<template>
    <div>
        <el-upload
            class="upload-demo"
            drag
            action="https://test.4power.sicifang.cc/shop/api/upload"
            :on-change="handleChange"
            :file-list="fileList"
            multiple
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div
                class="el-upload__tip"
                slot="tip"
            >只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
    </div>
</template>

<script>
export default {
  name: "米悦官网Index",

  data() {
    return {
        fileList: []
    };
  },

  mounted() {},

  methods: {
    handleChange(file, fileList) {
        console.log('file',file);
        console.log('fileList',fileList);
    //   this.fileList = fileList.slice(-3);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>